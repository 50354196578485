
      export default {
  "__schema": {
    "types": [
      {
        "kind": "UNION",
        "name": "AuthTokenResult",
        "possibleTypes": [
          {
            "name": "AuthToken"
          },
          {
            "name": "Error"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "CreateConnectionResult",
        "possibleTypes": [
          {
            "name": "ConnectedPumpResult"
          },
          {
            "name": "NotConnectedResult"
          }
        ]
      }
    ]
  }
}
    