import React from "react";
import { MenuContainer } from "@smartheat/common/deviceui/MenuContainer/MenuContainer";
import { PumpListScreen } from "./PumpListScreen";

// import { ReactComponent as AccountIcon } from "@smartheat/common/svg/account.svg";
// import { ReactComponent as AddIcon } from "@smartheat/common/svg/add.svg";

import AddIconAsset from "@smartheat/common/png/streamline-icon-add-bold@40x40.png";
import LogoutIconAsset from "@smartheat/common/png/streamline-icon-logout-2@40x40.png";
import CogBrowserIconAsset from "@smartheat/common/png/streamline-icon-cog-browser@40x40.png";

import { useAuth } from "../../Auth/useAuth";
import { signOut } from "../../../auth";

const menu = [
  // {
  //   path: `/device/account`,
  //   title: "Аккаунт",
  //   Icon: AccountIcon,
  // },
  {
    path: `/device/add`,
    title: "Добавить",
    Icon: () => <img src={AddIconAsset} alt="Добавить" />,
  },
  {
    path: `/signout`,
    onClick: event => {
      event.preventDefault();
      signOut();
    },
    title: "Выйти",
    Icon: () => <img src={LogoutIconAsset} alt="Выйти" />,
  },
];

const adminMenu = [
  {
    path: `/admin/pumps`,
    title: "Админка",
    Icon: () => <img src={CogBrowserIconAsset} alt="Админка" />,
    admin: true,
  },
  ...menu,
];

export function PumpList() {
  const viewer = useAuth();

  return (
    <MenuContainer menu={viewer.role === "ADMIN" ? adminMenu : menu}>
      <PumpListScreen />
    </MenuContainer>
  );
}
