const TOKEN_KEY = "batok";

export function getAuthToken() {
  return localStorage.getItem(TOKEN_KEY);
}

export function signInWithToken(token) {
  localStorage.setItem(TOKEN_KEY, token);
  document.location.pathname = "/";
}

export function signOut() {
  localStorage.removeItem(TOKEN_KEY);
  document.location.pathname = "/";
}
