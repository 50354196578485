import _toConsumableArray from "/home/denis/workspace/smartheat/pump-services/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "/home/denis/workspace/smartheat/pump-services/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
export function newRangeWorker(event, _ref, _ref2) {
  var setDisplayTimeByAngle = _ref.setDisplayTimeByAngle,
      setWorkerState = _ref.setWorkerState;
  var clockPos = _ref2.clockPos;
  setWorkerState({
    initialRawAngle: clockPos.rawAngle,
    direction: null,
    from: clockPos.angle,
    to: clockPos.angle,
    outOfBound: false
  });
  setDisplayTimeByAngle(clockPos.angle);
  return {
    type: "NEW_RANGE",
    handleDrag: function handleDrag(event, _ref3, _ref4) {
      var setDisplayTimeByAngle = _ref3.setDisplayTimeByAngle,
          workerState = _ref3.workerState,
          setWorkerState = _ref3.setWorkerState;
      var clockPos = _ref4.clockPos,
          distance = _ref4.distance;

      if (!workerState.direction && clockPos.rawAngle === workerState.initialRawAngle) {
        return;
      }

      var direction = workerState.direction || (clockPos.rawAngle > workerState.initialRawAngle ? "FORWARD" : "BACKWARD");
      var outOfBound = distance > 50;
      setWorkerState(_objectSpread(_objectSpread({}, workerState), {}, {
        from: direction === "BACKWARD" && clockPos.angle <= workerState.to ? clockPos.angle : workerState.from,
        to: direction === "FORWARD" && clockPos.angle >= workerState.from ? clockPos.angle : workerState.to,
        outOfBound: outOfBound,
        direction: direction
      }));
      setDisplayTimeByAngle(!outOfBound ? clockPos.angle : null);
    },
    handleDragEnd: function handleDragEnd(event, _ref5) {
      var ranges = _ref5.ranges,
          workerState = _ref5.workerState,
          setDisplayTimeByAngle = _ref5.setDisplayTimeByAngle,
          onRangesChange = _ref5.onRangesChange;

      if (!workerState.outOfBound && workerState.from !== workerState.to) {
        onRangesChange([].concat(_toConsumableArray(ranges), [{
          from: workerState.from,
          to: workerState.to,
          outOfBound: false
        }]));
      }

      setDisplayTimeByAngle(null);
    }
  };
}