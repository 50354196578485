import { RangeEdge } from "../constants";
export function zeroPointWorker(event, _ref, _ref2) {
  var ranges = _ref.ranges,
      setWorkerState = _ref.setWorkerState,
      handleEdgeMove = _ref.handleEdgeMove;
  var clockPos = _ref2.clockPos,
      distance = _ref2.distance;
  var direction = event.localPos.y > 0 ? "FORWARD" : "BACKWARD";
  var rangeIndex = direction === "FORWARD" ? 0 : ranges.length - 1;
  var edge = direction === "FORWARD" ? RangeEdge.From : RangeEdge.To;
  setWorkerState({
    rangeIndex: rangeIndex,
    edge: edge
  });
  handleEdgeMove(event, {
    index: rangeIndex,
    edge: edge,
    clockPos: clockPos,
    distance: distance
  });
  return {
    type: "ZEROPOINT",
    handleDrag: function handleDrag(event, _ref3, _ref4) {
      var workerState = _ref3.workerState,
          handleEdgeMove = _ref3.handleEdgeMove;
      var clockPos = _ref4.clockPos,
          distance = _ref4.distance;
      var edge = workerState.edge,
          rangeIndex = workerState.rangeIndex;
      handleEdgeMove(event, {
        index: rangeIndex,
        edge: edge,
        clockPos: clockPos,
        distance: distance
      });
    },
    handleDragEnd: function handleDragEnd(event, _ref5) {
      var workerState = _ref5.workerState,
          handleEdgeMoveEnd = _ref5.handleEdgeMoveEnd;
      var edge = workerState.edge,
          rangeIndex = workerState.rangeIndex;
      handleEdgeMoveEnd(rangeIndex, edge);
    }
  };
}