import React, { useRef } from "react";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";

import { useSprings, animated, config } from "react-spring";
import { useDrag } from "react-use-gesture";

import CloudOffIconAsset from "@smartheat/common/png/streamline-icon-cloud-off@24x24.png";
import CloudWifiIconAsset from "@smartheat/common/png/streamline-icon-cloud-wifi@24x24.png";

import { ReactComponent as DeleteIcon } from "@smartheat/common/svg/bin-2.svg";
import { ReactComponent as EditIcon } from "@smartheat/common/svg/pencil-2.svg";

const Container = styled.div`
  flex: 1;
  padding-top: 60px;
`;

const PumpSlot = styled.div`
  position: relative;
  min-height: 60px;
  border-bottom: 1px solid #ddd;

  &:first-of-type {
    border-top: 1px solid #ddd;
  }
`;

const PumpCard = styled(animated.div)`
  min-height: 60px;
  background-color: white;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  cursor: pointer;
`;

const BackMenu = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
`;

const BackMenuButton = styled.button`
  background: none;
  border: 0 none;
  padding: 0;
  cursor: pointer;
  width: 60px;
  height: 60px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none;
  }

  svg {
    display: block;
    fill: white;
  }
`;

const EditButton = styled(BackMenuButton)`
  background-color: #4d9ade;
`;

const DeleteButton = styled(BackMenuButton)`
  background-color: #de4e4e;
`;

const menuItemWidth = 60;
const menuWidth = 2 * menuItemWidth;

function setSpringByIndex(setSprings, index, value, velocity = 0) {
  setSprings(i => (i === index ? { ...value, config: { ...config.default, velocity } } : undefined));
}

export function List({ pumps }) {
  const history = useHistory();
  const offsetRef = useRef();
  const openedIndexRef = useRef(null);
  const shouldPreventClickRef = useRef(null);
  const [springs, setSprings] = useSprings(pumps.length, () => ({ x: 0 }));

  const bind = useDrag(({ args: [index], first, last, delta: [deltaX], vxvy: [vx], velocity, dragging, distance }) => {
    if (last) {
      if (openedIndexRef.current === index) {
        const shouldBeClosed = offsetRef.current > -menuWidth + menuItemWidth || vx > 0.5;

        setSpringByIndex(setSprings, index, { x: shouldBeClosed ? 0 : -menuWidth }, velocity);

        if (shouldBeClosed) {
          openedIndexRef.current = null;
        }
      } else {
        const shouldBeOpened = offsetRef.current < -menuItemWidth || vx < -0.5;

        setSpringByIndex(setSprings, index, { x: shouldBeOpened ? -menuWidth : 0 }, velocity);

        if (shouldBeOpened) {
          if (openedIndexRef.current !== null) {
            setSpringByIndex(setSprings, openedIndexRef.current, { x: 0 });
          }
          openedIndexRef.current = index;
        }
      }

      return;
    }

    if (first) {
      offsetRef.current = openedIndexRef.current === index ? -menuWidth : 0;
      shouldPreventClickRef.current = false;
    } else {
      if (distance >= 3) {
        shouldPreventClickRef.current = true;
      }
    }

    const newOffset = Math.min(Math.max(offsetRef.current + deltaX, -menuWidth), 0);
    offsetRef.current = newOffset;

    setSpringByIndex(setSprings, index, { x: newOffset });
  });

  const handlePumpClick = i => {
    if (shouldPreventClickRef.current) {
      shouldPreventClickRef.current = false;
      return;
    }

    history.push(`/device/${pumps[i].id}`);
  };

  return (
    <Container>
      {springs.map(({ x }, i) => (
        <PumpSlot key={i}>
          <PumpCard
            {...bind(i)}
            style={{ transform: x.interpolate(v => `translate3d(${v}px, 0, 0)`) }}
            onClick={() => handlePumpClick(i)}
          >
            <div>{pumps[i].title}</div>
            <img
              src={pumps[i].networkNode && pumps[i].networkNode.online ? CloudWifiIconAsset : CloudOffIconAsset}
              alt="Status"
            />
          </PumpCard>
          <BackMenu>
            <EditButton>
              <EditIcon />
            </EditButton>
            <DeleteButton>
              <DeleteIcon />
            </DeleteButton>
          </BackMenu>
        </PumpSlot>
      ))}
    </Container>
  );
}
