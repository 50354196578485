import _defineProperty from "/home/denis/workspace/smartheat/pump-services/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "/home/denis/workspace/smartheat/pump-services/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import u from "updeep";
import Konva from "konva";
import { useState } from "react";
import { getClockPos, normalizeRanges, normalizeAngle } from "../utils";
import { RangeEdge } from "./constants";
import { zeroPointWorker } from "./workers/zeroPointWorker";
import { newRangeWorker } from "./workers/newRangeWorker";
import { resizingWorker } from "./workers/resizingWorker";
import { angleData } from "../angleData";

function getEdgePosition(angle, radius) {
  var normalizedAngle = normalizeAngle(angle);
  var data = angleData[normalizedAngle];
  return {
    x: data.cos * radius,
    y: data.sin * radius
  };
}

export function useRingDragging(_ref) {
  var RADIUS = _ref.RADIUS,
      ranges = _ref.ranges,
      setRanges = _ref.setRanges,
      setDisplayTimeByAngle = _ref.setDisplayTimeByAngle,
      onRangesChange = _ref.onRangesChange;

  var _useState = useState(null),
      _useState2 = _slicedToArray(_useState, 2),
      dragWorker = _useState2[0],
      setDragWorker = _useState2[1];

  var _useState3 = useState(null),
      _useState4 = _slicedToArray(_useState3, 2),
      workerState = _useState4[0],
      setWorkerState = _useState4[1];

  var handleEdgeMove = function handleEdgeMove(event, _ref2) {
    var index = _ref2.index,
        edge = _ref2.edge,
        clockPos = _ref2.clockPos,
        distance = _ref2.distance;
    var range = ranges[index];
    var _ranges$index = ranges[index],
        from = _ranges$index.from,
        to = _ranges$index.to,
        outOfBound = _ranges$index.outOfBound;
    var nextAngle = clockPos.angle;
    var realPos = clockPos.realPos;
    var nextOutOfBound = distance > 50;

    if (nextOutOfBound) {
      var lastPosition = outOfBound ? range.lastPosition : getEdgePosition(edge === RangeEdge.From ? from : to, RADIUS);
      var translation = {
        x: realPos.x - lastPosition.x,
        y: realPos.y - lastPosition.y
      };
      setRanges(u(_defineProperty({}, index, {
        outOfBound: nextOutOfBound,
        lastPosition: lastPosition,
        translation: translation
      })));
    } else {
      var fromChanged = edge === RangeEdge.From && nextAngle !== from && nextAngle <= to;
      var toChanged = edge === RangeEdge.To && nextAngle !== to && nextAngle >= from;
      var edgeChanged = fromChanged || toChanged;
      var outOfBoundChanged = outOfBound !== nextOutOfBound;

      if (edgeChanged || outOfBoundChanged) {
        setRanges(u(_defineProperty({}, index, {
          from: u["if"](fromChanged, nextAngle),
          to: u["if"](toChanged, nextAngle),
          outOfBound: nextOutOfBound,
          translation: null,
          lastPosition: null
        })));
      }

      if (edgeChanged) {
        setDisplayTimeByAngle(nextAngle);
      }
    }
  };

  var handleEdgeMoveEnd = function handleEdgeMoveEnd() {
    setRanges(normalizeRanges);
    setDisplayTimeByAngle(null);
  };

  var props = {
    ranges: ranges,
    workerState: workerState,
    setWorkerState: setWorkerState,
    setDisplayTimeByAngle: setDisplayTimeByAngle,
    handleEdgeMove: handleEdgeMove,
    handleEdgeMoveEnd: handleEdgeMoveEnd,
    onRangesChange: onRangesChange
  };

  var handleDragEnd = function handleDragEnd(event) {
    if (dragWorker) {
      dragWorker.handleDragEnd(event, props);
      setDragWorker(null);
      setWorkerState(null);
    }
  };

  var handleDrag = function handleDrag(event) {
    var pos = event.target.getStage().getPointerPosition();
    var localPos = event.target.toLocalPosition(pos);
    var clockPos = getClockPos(localPos);
    var distance = RADIUS - clockPos.distance;

    if (dragWorker) {
      return dragWorker.handleDrag(event, props, {
        clockPos: clockPos,
        distance: distance
      });
    }

    var hasZeroIntersection = ranges.length > 0 && ranges[0].from === 0 && ranges[ranges.length - 1].to === 360;

    if (clockPos.angle % 360 === 0 && hasZeroIntersection) {
      setDragWorker(zeroPointWorker(event, props, {
        clockPos: clockPos,
        distance: distance
      }));
      return;
    }

    var affectedRangeIndex = ranges.findIndex(function (range) {
      return clockPos.angle >= range.from && clockPos.angle <= range.to;
    });

    if (affectedRangeIndex === -1) {
      if (ranges.length >= 3) return Konva.DD.CANCEL;
      setDragWorker(newRangeWorker(event, props, {
        clockPos: clockPos,
        distance: distance
      }));
      return;
    }

    var affectedRange = ranges[affectedRangeIndex];
    var dragFromEdge = affectedRange.from === clockPos.angle;
    var dragToEdge = affectedRange.to === clockPos.angle;

    if (dragFromEdge || dragToEdge) {
      setDragWorker(resizingWorker(event, props, {
        clockPos: clockPos,
        rangeIndex: affectedRangeIndex,
        edge: dragFromEdge ? RangeEdge.From : RangeEdge.To
      }));
      return;
    }

    return Konva.DD.CANCEL;
  };

  return {
    workerType: dragWorker && dragWorker.type,
    workerState: workerState,
    handleDrag: handleDrag,
    handleDragEnd: handleDragEnd
  };
}