import React from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/react-hooks";
import { loader } from "graphql.macro";

import { ReactComponent as BroskLogo } from "@smartheat/common/svg/broskwhite.svg";
import { signInWithToken } from "../../auth";

const CreateAuthTokenMutation = loader("./CreateAuthTokenMutation.graphql");

const Container = styled.div`
  background-color: #006ed2;
  color: white;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
`;

const FormContainer = styled.div`
  max-width: 420px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WelcomeText = styled.div`
  font-size: 24px;
  margin: 40px 0 60px;
`;

const HintText = styled.div`
  width: 100%;
  text-align: center;
  padding: 6px 0;
  border-radius: 6px;
  color: white;
  margin-bottom: 16px;
`;

const ErrorText = styled(HintText)`
  background-color: rgba(255, 0, 0, 0.5);
`;

const Field = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Input = styled.input`
  color: white;
  background-color: ${p => (p.error ? "rgba(255,0,0,0.5)" : "rgba(255,255,255,0.3)")};
  height: 37px;
  border: 0 none;
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;

  &::placeholder {
    color: #cccccc;
  }

  &:focus {
    outline: none;
  }
`;

const Submit = styled.button`
  border: 0 none;
  border-radius: 10px;
  background-color: #4d9adf;
  color: white;
  height: 52px;
  padding: 0 50px;

  &:disabled {
    opacity: 0.66;
    color: rgba(255, 255, 255, 0.66);
  }
`;

function getHint(loading, errors) {
  if (loading) {
    return <HintText>Отправляем данные...</HintText>;
  }

  if (errors.submit) {
    return <ErrorText>{errors.submit.message}</ErrorText>;
  }

  return <HintText>Войдите в свой аккаунт</HintText>;
}

export function SignIn() {
  const { handleSubmit, register, errors, setError } = useForm();

  const [createAuthToken, { loading }] = useMutation(CreateAuthTokenMutation);

  const onSubmit = async values => {
    console.log(values);
    try {
      const res = await createAuthToken({ variables: { input: values } });
      const tokenRes = res.data.createAuthToken;

      if (tokenRes.token) {
        signInWithToken(tokenRes.token);
        return;
      }

      if (tokenRes.code === "AUTHENTICATED_ALREADY") {
        setError("submit", "res", "Сессия уже активна. Обновите страницу.");
        return;
      }

      if (tokenRes.code === "WRONG_CREDENTIALS") {
        setError("submit", "res", "Неверный e-mail или пароль");
        return;
      }

      if (tokenRes.code === "SYSTEM_ERROR") {
        setError("submit", "res", "Ошибка запроса. Попробуйте позже");
      }
    } catch (err) {
      console.error(err);
      setError("submit", "query", "Ошибка запроса. Попробуйте позже");
    }
  };

  return (
    <Container>
      <FormContainer>
        <BroskLogo />
        <WelcomeText>Добро пожаловать!</WelcomeText>
        {getHint(loading, errors)}
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field>
            <Input
              name="email"
              placeholder="E-mail"
              error={!!errors.email}
              ref={register({
                required: "Required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "invalid email address",
                },
              })}
            />
          </Field>

          <Field>
            <Input
              type="password"
              name="password"
              placeholder="Пароль"
              error={!!errors.password}
              ref={register({
                required: "Required",
              })}
            />
          </Field>

          <div>
            <Submit type="submit" disabled={loading}>
              Войти
            </Submit>
          </div>
        </Form>
      </FormContainer>
    </Container>
  );
}
