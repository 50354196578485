import { useContext } from "react";
import { DeviceDataContext } from "./DeviceDataContext";
export function useDeviceData() {
  var data = useContext(DeviceDataContext);

  if (!data) {
    throw new Error("Provide data to DeviceDataContext");
  }

  return data;
}