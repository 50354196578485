import _toConsumableArray from "/home/denis/workspace/smartheat/pump-services/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "/home/denis/workspace/smartheat/pump-services/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
var wholeDay = 24 * 60;
export var timeToAngle = function timeToAngle(time) {
  return time / wholeDay * 360;
};
export var angleToTime = function angleToTime(angle) {
  return angle / 360 * wholeDay;
};
export var normalizeAngle = function normalizeAngle(angle) {
  return (angle % 360 + 360) % 360;
};
export var getClockPos = function getClockPos(localPos) {
  var x = localPos.x,
      y = localPos.y;
  var rawAngle = normalizeAngle(Math.atan2(y, x) / Math.PI * 180);
  var fraction = 360 / 48;
  var boundAngle = Math.round(rawAngle / fraction) * fraction;
  var distance = Math.sqrt(x * x + y * y);
  return {
    distance: distance,
    angle: boundAngle,
    rawAngle: rawAngle,
    realPos: localPos
  };
};
export function fixRange(range) {
  if (range[0] <= range[1]) return range;
  return [range[1], range[0]];
}
export var convertRangesToTimed = function convertRangesToTimed(ranges) {
  return ranges.map(function (range) {
    return range.map(angleToTime);
  });
};
export function normalizeRanges(ranges) {
  var normalized = ranges.filter(function (_ref) {
    var from = _ref.from,
        to = _ref.to,
        outOfBound = _ref.outOfBound;
    return from !== to && !outOfBound;
  }).map(function (range) {
    return _objectSpread({}, range);
  }).sort(function (rangeA, rangeB) {
    return rangeA.from - rangeB.from;
  });

  if (normalized.length <= 1) {
    return normalized;
  }

  for (var i = 0; i < normalized.length; i++) {
    var baseRange = normalized[i];
    if (!baseRange) continue;

    for (var j = i + 1; j < ranges.length; j++) {
      var range = normalized[j];
      if (!range) continue;
      if (range.from > baseRange.to) break;
      normalized[j] = null;

      if (range.to > baseRange.to) {
        baseRange.to = range.to;
      }
    }
  }

  return normalized.filter(function (range) {
    return !!range;
  });
}
export function getHoleRange(angle, from, to) {
  var unitAngle = 360 / 48;
  var size = (to - from) / unitAngle;
  if (size <= 1) return null;
  if (size <= 2 && from !== 0 && to !== 360) return null;
  var nextAngle = angle + unitAngle;

  if (nextAngle < to || nextAngle === 360) {
    return [angle, nextAngle];
  }

  return [angle - unitAngle, angle];
}
export function getSplittedRanges(ranges, angle) {
  var offset = 0;

  for (var i = 0; i < ranges.length; i++) {
    var _ranges$i = ranges[i],
        from = _ranges$i.from,
        to = _ranges$i.to;

    if (angle === from || angle === to) {
      return null;
    }

    if (angle < from) {
      var _validRange = getHoleRange(angle, offset, from);

      if (!_validRange) return null;
      var newRanges = ranges.slice();
      newRanges.splice(i, 0, {
        from: _validRange[0],
        to: _validRange[1],
        outOfBound: false
      });
      return newRanges;
    }

    if (angle < to) {
      var _validRange2 = getHoleRange(angle, from, to);

      if (!_validRange2) return null;

      var _newRanges = ranges.slice();

      _newRanges.splice(i, 1, {
        from: from,
        to: _validRange2[0],
        outOfBound: false
      }, {
        from: _validRange2[1],
        to: to,
        outOfBound: false
      });

      return _newRanges;
    }

    offset = to;
  }

  var validRange = getHoleRange(angle, offset, 360);
  return validRange ? [].concat(_toConsumableArray(ranges), [{
    from: validRange[0],
    to: validRange[1],
    outOfBound: false
  }]) : null;
}