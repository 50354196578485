import React from "react";
import { useParams } from "react-router-dom";

import { AdminServiceMenu } from "@smartheat/common/deviceui/ServiceMenuScreen/AdminServiceMenu";
import { ConfigContext } from "@smartheat/common/deviceui/Config/ConfigContext";

import { DeviceQueryProvider } from "./DeviceQueryProvider";
import { config } from "./config";

export function ServiceMenuAdapter() {
  const { id } = useParams();

  return (
    <ConfigContext.Provider value={config}>
      <DeviceQueryProvider id={id}>
        <AdminServiceMenu backTo="/admin/pumps" />
      </DeviceQueryProvider>
    </ConfigContext.Provider>
  );
}
