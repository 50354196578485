import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useMutation } from "@apollo/react-hooks";
import { loader } from "graphql.macro";
import { useParams } from "react-router-dom";

import { signInWithToken } from "../../auth";
import { FullScreenMessage } from "../../components/FullScreenMessage/FullScreenMessage";

const AuthWithATokenMutation = loader("./AuthWithATokenMutation.graphql");

const FullScreenMessageWithButton = styled(FullScreenMessage)`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    border: 0 none;
    border-radius: 4px;
    margin-top: 32px;
    font-size: 20px;
    background-color: #4D9ADF;
    color: #222222;
    padding: 12px 20px;
  }
`;


export function SignInWithAToken() {
  const params = useParams();
  const [error, setError] = useState(null);
  const [authWithAToken] = useMutation(AuthWithATokenMutation);

  const atoken = params && params.atoken;

  useEffect(() => {
    let mounted = true;

    (async () => {
      try {
        const res = await authWithAToken({ variables: { input: { atoken } } });
        const tokenRes = res.data.authWithAToken;

        if (!mounted) return;

        if (tokenRes.token) {
          signInWithToken(tokenRes.token);
          return;
        }

        if (tokenRes.code === "WRONG_ATOKEN") {
          setError("Недействительная ссылка");
          return;
        }
      } catch (err) {
        console.error(err);
      }

      setError("Системная ошибка. Попробуйте позже");
    })();

    return () => {
      mounted = false;
    };
  }, [atoken]);

  if (error) {
    return (
      <FullScreenMessageWithButton>
        <div>{error}</div>
        <button
          onClick={() => {
            window.location = "/";
          }}
        >
          На главную
        </button>

      </FullScreenMessageWithButton>
    );
  }

  return <FullScreenMessage>Авторизация...</FullScreenMessage>;
}
