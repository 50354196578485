import u from "updeep";
import sortBy from "lodash/sortBy";
import { loader } from "graphql.macro";

import { useCustomEffect } from "../useCustomEffect";

const OnlineStatusSubscription = loader("./OnlineStatusSubscription.graphql");

export function useOnlineStatusSubscription(key, data, subscribeToMore) {
  const nodes = data && data[key].filter(pump => !!pump.networkNode).map(pump => pump.networkNode.id);

  useCustomEffect(
    () => {
      const unsubscribe = subscribeToMore({
        document: OnlineStatusSubscription,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data || !subscriptionData.data.onlineStatus) {
            return prev;
          }

          const { nodeId, online } = subscriptionData.data.onlineStatus;

          return u({ [key]: u.map({ networkNode: u.if(n => n && n.id === nodeId, { online }) }) }, prev);
        },
        variables: { nodes },
      });

      return () => {
        unsubscribe();
      };
    },
    prev => {
      if (!nodes || nodes.length === 0) {
        return undefined;
      }

      const sortedNodes = sortBy(nodes);

      return prev && sortedNodes.every((node, idx) => node === prev[idx]) ? prev : sortedNodes;
    },
  );
}
