import { useState, useEffect, useRef } from "react";

function getValue(mediaQueryLists, values, defaultValue) {
  // Get index of first media query that matches
  const index = mediaQueryLists.findIndex(mql => mql.matches); // Return related value or defaultValue if none

  return typeof values[index] !== "undefined" ? values[index] : defaultValue;
}

export function useMedia(queries, values = [true], defaultValue = false) {
  // Array containing a media query list for each query
  const mediaQueryListsRef = useRef();

  if (!mediaQueryListsRef.current) {
    mediaQueryListsRef.current = queries.map(q => window.matchMedia(q));
  } // State and setter for matched value


  const [value, setValue] = useState(() => getValue(mediaQueryListsRef.current, values, defaultValue));
  useEffect(() => {
    const mediaQueryLists = mediaQueryListsRef.current;

    const handler = () => setValue(getValue(mediaQueryLists, values, defaultValue)); // Set a listener for each media query with above handler as callback.


    mediaQueryLists.forEach(mql => mql.addListener(handler)); // Remove listeners on cleanup

    return () => mediaQueryLists.forEach(mql => mql.removeListener(handler));
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [] // Empty array ensures effect is only run on mount and unmount
  );
  return value;
}