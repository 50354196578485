import { useContext } from "react";
import { DeviceQueryContext } from "./DeviceQueryContext";
export function useDeviceQueryApi() {
  var api = useContext(DeviceQueryContext);

  if (!api) {
    throw new Error("Provide api to DeviceQueryContext");
  }

  return api;
}