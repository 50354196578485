import { ApolloClient } from "apollo-client";
import { InMemoryCache, IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { WebSocketLink } from "apollo-link-ws";

import introspectionResult from "./introspection-result";

import { getAuthToken } from "./auth";
import { createStatusManager, CLIENT_STATUS_CONNECTING } from "./utils/statusManager";

const apiHost =
  process.env.NODE_ENV !== "production" ? `ws://localhost:5050/graphql` : `wss://${document.location.hostname}/graphql`;

const subscriptionClient = new SubscriptionClient(apiHost, {
  reconnect: true,
  connectionParams: () => ({ jwt: getAuthToken() }),
});

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: introspectionResult,
});

const cache = new InMemoryCache({ fragmentMatcher });
const wsLink = new WebSocketLink(subscriptionClient);
export const statusManager = createStatusManager(subscriptionClient, CLIENT_STATUS_CONNECTING);
export const apolloClient = new ApolloClient({
  cache: cache,
  link: wsLink,
  connectToDevTools: true,
});

// setTimeout(() => {
//   console.log("disconnecting...");
//   subscriptionClient.close(true, true);
// }, 10000);
