export const UnitVariant = {};
const DISCRIMINANT_KEY = "___RUST_ENUM_DISCRIMINANT___";
export function RustEnum(spec) {
  let E = {};

  for (let discriminant in spec) {
    let cons = spec[discriminant];

    let is = that => that ? that[DISCRIMINANT_KEY] === discriminant : false;

    if (cons === UnitVariant) {
      E[discriminant] = {
        [DISCRIMINANT_KEY]: discriminant,
        is
      };
    } else {
      E[discriminant] = (...args) => ({ ...cons.apply(null, args),
        [DISCRIMINANT_KEY]: discriminant,
        is,
        toString: () => discriminant
      });
    }

    E[discriminant][DISCRIMINANT_KEY] = discriminant;

    E[discriminant].toString = () => discriminant;
  }

  return E;
}