import React from "react";
import styled from "styled-components/macro";

const Background = styled.div`
  position: absolute;
  min-width: 100%;
  height: 100%;
  background-color: #eee;
  display: flex;
  z-index: 1;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 540px;
  min-width: 360px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: white;
  overflow: hidden;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.12);
`;

export function DeviceLayout({ children }) {
  return (
    <Background>
      <Container id="AppContent">{children}</Container>
    </Background>
  );
}
