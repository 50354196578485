import { EventEmitter } from "eventemitter3";

export const CLIENT_STATUS_CONNECTED = "CONNECTED";
export const CLIENT_STATUS_CONNECTING = "CONNECTING";
export const CLIENT_STATUS_RECONNECTED = "RECONNECTED";
export const CLIENT_STATUS_RECONNECTING = "RECONNECTING";
export const CLIENT_STATUS_DISCONNECTED = "DISCONNECTED";

export function createStatusManager(subscriptionClient, initialStatus = CLIENT_STATUS_DISCONNECTED) {
  const eventEmitter = new EventEmitter();
  let status = initialStatus;

  function setStatus(newStatus) {
    status = newStatus;
    eventEmitter.emit("status", newStatus);
  }

  subscriptionClient.onConnected(() => {
    setStatus(CLIENT_STATUS_CONNECTED);
  });

  subscriptionClient.onConnecting(() => {
    setStatus(CLIENT_STATUS_CONNECTING);
  });

  subscriptionClient.onReconnected(() => {
    setStatus(CLIENT_STATUS_RECONNECTED);
  });

  subscriptionClient.onReconnecting(() => {
    setStatus(CLIENT_STATUS_RECONNECTING);
  });

  subscriptionClient.onDisconnected(() => {
    setStatus(CLIENT_STATUS_DISCONNECTED);
  });

  return {
    get subscriptionClient() {
      return subscriptionClient;
    },
    get status() {
      return status;
    },
    onStatusChanged(callback) {
      const handler = eventEmitter.on("status", callback);

      return () => {
        handler.off("status", callback);
      };
    },
  };
}
