import React, { Fragment, useEffect, useState, useRef } from "react";
import styled from "styled-components/macro";

import {
  CLIENT_STATUS_CONNECTING,
  CLIENT_STATUS_RECONNECTING,
  CLIENT_STATUS_DISCONNECTED,
  CLIENT_STATUS_CONNECTED,
} from "../../utils/statusManager";

import { FullScreenMessage } from "../FullScreenMessage/FullScreenMessage";

const DisconnectedFullScreenMessage = styled(FullScreenMessage)`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    border: 0 none;
    border-radius: 4px;
    margin-top: 32px;
    font-size: 20px;
    background-color: #4D9ADF;
    color: #222222;
    padding: 12px 20px;
  }
`;

export function OnlineStatus({ manager, children }) {
  const wasConnectedRef = useRef(false);
  const [status, setStatus] = useState(() => manager.status);

  useEffect(() => {
    return manager.onStatusChanged(newStatus => {
      if (newStatus === CLIENT_STATUS_CONNECTED) {
        wasConnectedRef.current = true;
      }
      setStatus(newStatus);
    });
  });

  if (status === CLIENT_STATUS_CONNECTING || status === CLIENT_STATUS_RECONNECTING) {
    return <FullScreenMessage>Подключение к серверу...</FullScreenMessage>;
  }

  if (status === CLIENT_STATUS_DISCONNECTED) {
    return (
      <DisconnectedFullScreenMessage>
        <div>
          {wasConnectedRef.current
            ? "Соединение с сервером прервано."
            : "Невозможно подключиться к серверу. Проверьте соединение с интеренетом или попробуйте позже."}
        </div>
        <button
          onClick={() => {
            window.location.reload(true);
          }}
        >
          Подключиться
        </button>
      </DisconnectedFullScreenMessage>
    );
  }

  return <Fragment>{children}</Fragment>;
}
