import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { useAuth } from "../Auth/useAuth";

import { PumpList } from "./PumpList/PumpList";
import { AddPump } from "./AddPump/AddPump";
import { DeviceLayout } from "./DeviceLayout/DeviceLayout";
import { ServiceMenuAdapter } from "./ServiceMenuAdapter";
import { AppAdapter } from "./AppAdapter";

export function DeviceAdapter() {
  const user = useAuth();

  return (
    <DeviceLayout>
      <Switch>
        <Route path="/device/list" component={PumpList} />
        <Route path="/device/add" component={AddPump} />
        {user.role === "ADMIN" && <Route path="/device/service-menu/:id" component={ServiceMenuAdapter} />}
        <Route path="/device/:id" component={AppAdapter} />
        <Redirect to="/" />
      </Switch>
    </DeviceLayout>
  );
}
