import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { useAuth } from "./Auth/useAuth";
import { Guest } from "./Auth/Guest";

import { SignIn } from "../pages/SignIn/SignIn";
import { PumpAdmin } from "../pages/PumpAdmin/PumpAdmin";
import { DeviceAdapter } from "./deviceAdapter/DeviceAdapter";
import { SignInWithAToken } from "../pages/SignInWithAToken/SignInWithAToken";

export function App() {
  const viewer = useAuth();

  if (viewer === Guest) {
    return (
      <Switch>
        <Route path="/signin/atoken/:atoken" component={SignInWithAToken} />
        <Route path="/signin" component={SignIn} />
        <Redirect to="/signin" />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route path="/signin/atoken/:atoken" component={SignInWithAToken} />
      <Route path="/device" component={DeviceAdapter} />
      {viewer.role === "ADMIN" && <Route path="/admin/pumps" component={PumpAdmin} />}
      <Redirect exact from="/" to={viewer.role === "ADMIN" ? "/admin/pumps" : "/device/list"} />
      <Redirect exact from="/admin" to="/admin/pumps" />
      <Redirect to="/" />
    </Switch>
  );
}
