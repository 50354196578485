import { RustEnum, UnitVariant } from "../../../utils/RustEnum";
export var ScreenKind = RustEnum({
  Menu: UnitVariant,
  Control: UnitVariant,
  Alarms: UnitVariant,
  Sensors: UnitVariant,
  Params: function Params(idx) {
    return {
      idx: idx
    };
  },
  DeviceScreen: function DeviceScreen(subscreen) {
    return {
      subscreen: subscreen
    };
  }
});