import _toConsumableArray from "/home/denis/workspace/smartheat/pump-services/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _toArray from "/home/denis/workspace/smartheat/pump-services/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toArray";
import { useCallback } from "react";
import { useDeviceQueryApi } from "./useDeviceQueryApi";
export function useSetRegisterMutation() {
  var queryApi = useDeviceQueryApi();

  var _queryApi$useSetRegis = queryApi.useSetRegisterMutation(),
      _queryApi$useSetRegis2 = _toArray(_queryApi$useSetRegis),
      setRegister = _queryApi$useSetRegis2[0],
      restData = _queryApi$useSetRegis2.slice(1);

  return [useCallback(function (name, value) {
    setRegister({
      variables: {
        input: {
          name: name,
          value: value
        }
      }
    });
  }, [setRegister])].concat(_toConsumableArray(restData));
}