import React, { useMemo } from "react";
import { useSubscription, useMutation, useQuery } from "@apollo/react-hooks";
import { DeviceQueryContext } from "@smartheat/common/deviceui/DeviceData/DeviceQueryContext";
import { loader } from "graphql.macro";

import { useUpdater } from "../../utils/useUpdater";

const RegistersSubscription = loader("./api/RegistersSubscription.graphql");
const SetRegisterMutation = loader("./api/SetRegisterMutation.graphql");
const AlarmRawEventsQuery = loader("./api/AlarmRawEventsQuery.graphql");
const AlarmJournalQuery = loader("./api/AlarmJournalQuery.graphql");
const AlarmsQuery = loader("./api/AlarmsQuery.graphql");
const AlarmsSubscription = loader("./api/AlarmsSubscription.graphql");
const HardwareInfoQuery = loader("./api/HardwareInfoQuery.graphql");
const ApiVersionQuery = loader("./api/ApiVersionQuery.graphql");
const ServiceMenuQuery = loader("./api/ServiceMenuQuery.graphql");
const UserAppConfigQuery = loader("./api/UserAppConfigQuery.graphql");
const RegisterConfigQuery = loader("./api/RegisterConfigQuery.graphql");

export function DeviceQueryProvider({ id, children }) {
  const api = useMemo(
    () => ({
      useRegistersSubscription(names) {
        const { data, ...rest } = useSubscription(RegistersSubscription, { variables: { names, id } });

        return {
          registersData: data && data.pump && data.pump.registers ? data.pump.registers : {},
          ...rest,
        };
      },
      useSetRegisterMutation() {
        return useMutation(SetRegisterMutation, { variables: { id } });
      },
      useAlarmsSubscription(options) {
        options = options || {};
        options.variables = options.variables || {};
        options.variables.id = id;

        const { data, ...rest } = useSubscription(AlarmsSubscription, options);

        return {
          data: data && data.pump,
          ...rest,
        };
      },
      useAlarmRawEventsQuery(options) {
        options = options || {};
        options.variables = options.variables || {};
        options.variables.id = id;

        const { data, ...rest } = useQuery(AlarmRawEventsQuery, options);

        return {
          data: data && data.pump,
          ...rest,
        };
      },
      useAlarmJournalQuery(options) {
        options = options || {};
        options.variables = options.variables || {};
        options.variables.id = id;

        const { data, ...rest } = useQuery(AlarmJournalQuery, options);

        return {
          data: data && data.pump,
          ...rest,
        };
      },
      useAlarms() {
        const { data, subscribeToMore } = useQuery(AlarmsQuery, { variables: { id } });

        useUpdater("alarms", AlarmsSubscription, subscribeToMore, { id });

        return data && data.pump && data.pump.alarms;
      },
      useHardwareInfoQuery() {
        const { data } = useQuery(HardwareInfoQuery, { variables: { id } });
        return data?.pump?.hardwareInfo ?? {};
      },
      useApiVersionQuery() {
        const { data } = useQuery(ApiVersionQuery, { variables: { id } });
        return data?.pump?.apiVersion ?? 0;
      },
      useServiceMenuQuery() {
        const { data } = useQuery(ServiceMenuQuery, { variables: { id } });
        return data?.pump?.serviceMenu;
      },
      useUserAppConfigQuery() {
        const { data } = useQuery(UserAppConfigQuery, { variables: { id } });
        return data?.pump?.userAppConfig;
      },
      useRegisterConfigQuery(registers) {
        const { data } = useQuery(RegisterConfigQuery, { variables: { id, registers } });
        return data?.pump?.registersConfig;
      },
    }),
    [id],
  );

  return <DeviceQueryContext.Provider value={api}>{children}</DeviceQueryContext.Provider>;
}
