import React from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { useTable } from "react-table";

import AppIconAsset from "@smartheat/common/png/streamline-icon-volume-up@24x24.png";
import ServiceIconAsset from "@smartheat/common/png/streamline-icon-cog-double-2@24x24.png";
import GraphIconAsset from "@smartheat/common/png/streamlinehq-analytics-graph-line-business-products-24.png";

import { signOut } from "../../auth";
import { useAuth } from "../../components/Auth/useAuth";

const Container = styled.div`
  background-color: #eee;
  min-height: 100%;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #888;
  background-color: white;
  padding: 8px 16px;
`;

const Content = styled.div`
  padding: 20px;
`;

const TableContainer = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  background-color: white;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    th,
    td {
      text-align: left;
      border-bottom: 1px solid rgba(224, 224, 224, 1);
      padding: 8px;
    }
  }
`;

const HeaderLink = styled(Link)`
  margin-right: 8px;
`;

const SignOutButton = styled.button`
  margin-left: 8px;
`;

const PumpLinks = styled.div`
  display: flex;
  justify-content: flex-end;
  user-select: none;
`;

const PumpLink = styled(Link)`
  margin-left: 12px;
  opacity: ${(p) => (p.$online ? 1 : 0.33)};
  pointer-events: ${(p) => (p.$online ? "all" : "none")};

  &:first-child {
    margin-left: 0;
  }

  img {
    display: block;
  }
`;

const PumpNetwork = styled.div`
  display: flex;
  align-items: center;
`;

const NetworkIndicator = styled.div`
  margin-right: 8px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(p) => (p.$online ? "#4fdf4d" : "#dd4e4e")};
`;

const PumpId = styled.div`
  margin-top: 2px;
  color: #7E7A85;
  font-size: 14px;
`;

const columns = [
  {
    Header: "Клиент",
    accessor: "customer.name",
  },
  {
    Header: "Название",
    accessor: "title",
    Cell: ({ cell: { value: title }, row }) => (
      <>
        <div>{title}</div>
        <PumpId>ID: {row.original.id}</PumpId>
      </>
    ),
  },
  {
    Header: "Сеть",
    accessor: "networkNode",
    Cell: ({ cell: { value: node } }) => (
      <PumpNetwork>
        {node && <NetworkIndicator $online={node.online} />}
        {node ? node.name : "n/a"}
      </PumpNetwork>
    ),
  },
  {
    id: "links",
    Cell: ({ row }) => (
      <PumpLinks>
        {row.original.networkNode && (
          <>
            <PumpLink
              to={`/device/${row.original.id}`}
              $online={row.original.networkNode ? row.original.networkNode.online : false}
            >
              <img src={AppIconAsset} alt="App" />
            </PumpLink>
            <PumpLink
              to={`/device/service-menu/${row.original.id}`}
              $online={row.original.networkNode ? row.original.networkNode.online : false}
            >
              <img src={ServiceIconAsset} alt="Сервисное меню" />
            </PumpLink>
            <PumpLink
              $online
              as="a"
              href={`http://app.brosk.ru:9999/d/1eM5Z5iVk/pumps?orgId=1&var-pumpId=${row.original.id}`}
              target="_blank"
            >
              <img src={GraphIconAsset} alt="Графики" />
            </PumpLink>
          </>
        )}
      </PumpLinks>
    ),
  },
];

export function PumpTable({ pumps }) {
  const auth = useAuth();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: pumps,
  });

  return (
    <Container>
      <Header>
        <div>
          <HeaderLink to="/admin/pumps">All pumps</HeaderLink>
          <HeaderLink to="/device/list">My pumps</HeaderLink>
        </div>
        <div>
          {auth.email}
          <SignOutButton
            onClick={() => {
              signOut();
            }}
          >
            Sign out
          </SignOutButton>
        </div>
      </Header>
      <Content>
        <TableContainer>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </TableContainer>
      </Content>
    </Container>
  );
}
