import u from "updeep";
import React, { useState, useRef, useEffect } from "react";
import styled, { keyframes } from "styled-components/macro";
import { useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import { loader } from "graphql.macro";

import { Header } from "@smartheat/common/deviceui/Header/Header";

const CreatePairingMutation = loader("./CreatePairingMutation.graphql");
const PumpsQuery = loader("../PumpList/PumpsQuery.graphql");

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Description = styled.div`
  text-align: center;
  margin: 60px 20px 40px;
  font-size: 24px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const FakeInput = styled.input`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 1px;
  width: 100%;
  display: block;
  opacity: 0.01;
  box-sizing: border-box;
  border: 0 none;
  background: transparent;
  padding: 1px;

  &:focus {
    outline: 0 none;
  }
`;

const shakeAnimation = keyframes`
  8%, 41% {
      transform: translateX(-10px);
  }
  25%, 58% {
      transform: translateX(10px);
  }
  75% {
      transform: translateX(-5px);
  }
  92% {
      transform: translateX(5px);
  }
  0%, 100% {
      transform: translateX(0);
  }
`;

const PinCode = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${p => (p.hasError ? shakeAnimation : "none")} 0.5s;
`;

const Char = styled.div`
  width: 40px;
  font-size: 40px;
  text-align: center;
`;

const Dot = styled.div`
  height: 20px;
  width: 20px;
  margin: 8px 10px 0;
  background-color: #dadddf;
  border-radius: 50%;
`;

export function AddPump() {
  const history = useHistory();
  const inputRef = useRef();
  const [hasError, setHasError] = useState(false);
  const [value, setValue] = useState("");

  const [createPairing, mutationResult] = useMutation(CreatePairingMutation, {
    update: (cache, { data }) => {
      const pump = data.createPairing.pump;

      if (!pump) {
        return;
      }

      let res;

      try {
        res = cache.readQuery({ query: PumpsQuery });
      } catch (err) {
        return;
      }

      cache.writeQuery({
        query: PumpsQuery,
        data: u({ myPumps: list => [...list, pump] }, res),
      });
    },
  });

  const handleInputBlur = e => {
    e.target.focus();
  };

  const handleInputChange = async e => {
    const newValue = e.target.value.replace(/\D/g, "").slice(0, 6);
    setValue(newValue);
    setHasError(false);

    if (newValue.length === 6) {
      try {
        const { data } = await createPairing({ variables: { pin: newValue } });

        if (data.createPairing.pump) {
          history.push(`/device/list`);
        } else if (data.createPairing.code === "WRONGPIN") {
          setValue("");
          setHasError(true);
        }
      } catch (err) {
        console.error(err);
        setValue("");
        setHasError(true);
      }
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <Container>
      <Header backTo="/device/list" />
      {(() => {
        if (mutationResult.loading) {
          return (
            <Content>
              <Description>Подключение...</Description>
            </Content>
          );
        }

        if (mutationResult.called && mutationResult.data.createPairing.code === "DUPLICATE") {
          return (
            <Content>
              <Description>Насос уже был подключён ранее</Description>
            </Content>
          );
        }

        return (
          <Content>
            <Description>Введите пин-код</Description>
            <FakeInput
              autoFocus
              type="text"
              value={value}
              ref={inputRef}
              onBlur={handleInputBlur}
              onChange={handleInputChange}
            />
            <PinCode hasError={hasError}>
              {value.length >= 1 ? <Char>{value[0]}</Char> : <Dot />}
              {value.length >= 2 ? <Char>{value[1]}</Char> : <Dot />}
              {value.length >= 3 ? <Char>{value[2]}</Char> : <Dot />}
              <Char>-</Char>
              {value.length >= 4 ? <Char>{value[3]}</Char> : <Dot />}
              {value.length >= 5 ? <Char>{value[4]}</Char> : <Dot />}
              {value.length >= 6 ? <Char>{value[5]}</Char> : <Dot />}
            </PinCode>
          </Content>
        );
      })()}
    </Container>
  );
}
