import { useRef, useEffect } from "react";

export function useCustomEffect(effect, checkFn) {
  const storeRef = useRef(undefined);
  storeRef.current = checkFn(storeRef.current);

  useEffect(() => {
    if (storeRef.current !== undefined) {
      return effect();
    }
  }, [storeRef.current]); // eslint-disable-line react-hooks/exhaustive-deps
}
