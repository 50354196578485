import { loader } from "graphql.macro";
import { useMemo } from "react";
import { useMutation } from "@apollo/react-hooks";

const HeatDataQuery = loader("./api/HeatDataQuery.graphql");

const StartForcingMutation = loader("./api/StartForcingMutation.graphql");
const StopForcingMutation = loader("./api/StopForcingMutation.graphql");
const StartVacationMutation = loader("./api/StartVacationMutation.graphql");
const StopVacationMutation = loader("./api/StopVacationMutation.graphql");
const SetModeTemperaturesMutation = loader("./api/SetModeTemperaturesMutation.graphql");
const ToggleManualModeMutation = loader("./api/ToggleManualModeMutation.graphql");
const SetDayScheduleMutation = loader("./api/SetDayScheduleMutation.graphql");

function createHeatConfigUpdater(key) {
  return (cache, { data }) => {
    const { [key]: heatConfigUpdates } = data;
    const res = cache.readQuery({ query: HeatDataQuery });
    cache.writeQuery({
      query: HeatDataQuery,
      data: { ...res, heatConfig: { ...res.heatConfig, ...heatConfigUpdates } },
    });
  };
}

export function useDeviceApiCreator(id) {
  const [startForcing] = useMutation(StartForcingMutation, { variables: { id } });
  const [stopForcing] = useMutation(StopForcingMutation, { variables: { id } });
  const [startVacation] = useMutation(StartVacationMutation, { variables: { id } });
  const [stopVacation] = useMutation(StopVacationMutation, { variables: { id } });
  const [setModeTemperatures] = useMutation(SetModeTemperaturesMutation, { variables: { id } });
  const [toggleManualMode, toggleManualModeResult] = useMutation(ToggleManualModeMutation, {
    variables: { id },
    update: createHeatConfigUpdater("toggleManualMode"),
  });
  const [setDaySchedule] = useMutation(SetDayScheduleMutation, { variables: { id } });

  return useMemo(
    () => ({
      startForcing,
      stopForcing,
      startVacation,
      stopVacation,
      setModeTemperatures,
      toggleManualMode,
      toggleManualModeResult,
      setDaySchedule,
    }),
    [
      startForcing,
      stopForcing,
      startVacation,
      stopVacation,
      setModeTemperatures,
      toggleManualMode,
      toggleManualModeResult,
      setDaySchedule,
    ],
  );
}
