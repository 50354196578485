import { useMemo } from "react";
import { useDeviceQueryApi } from "./useDeviceQueryApi";

function makeRegisterConfig(list) {
  return list.reduce(function (memo, config) {
    memo[config.id] = config;
    return memo;
  }, {});
}

export function useRegisterConfigQuery(registers) {
  var queryApi = useDeviceQueryApi();
  var list = queryApi.useRegisterConfigQuery(registers);
  return useMemo(function () {
    return list && makeRegisterConfig(list);
  }, [list]);
}