import React from "react";
import styled from "styled-components/macro";

const Container = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  color: #222222;
  background-color: white;
  font-size: 24px;
  text-align: center;
`;

export function FullScreenMessage({ className, children }) {
  return (
    <Container>
      <div className={className}>{children}</div>
    </Container>
  );
}
