import React from "react";
import styled from "styled-components/macro";
import { useQuery } from "@apollo/react-hooks";
import { loader } from "graphql.macro";

import { ReactComponent as MenuIcon } from "@smartheat/common/svg/menu.svg";
import { ReactComponent as IconPlus } from "@smartheat/common/svg/pluscircleblue.svg";

import { Header } from "@smartheat/common/deviceui/Header/Header";
import { TextPage } from "@smartheat/common/deviceui/TextPage/TextPage";
import { useMenuApi } from "@smartheat/common/deviceui/MenuContainer/useMenuApi";
import { List } from "./List";
import { Link } from "react-router-dom";
import { useOnlineStatusSubscription } from "../../../utils/useOnlineStatusSubscription/useOnlineStatusSubscription";

const PumpsQuery = loader("./PumpsQuery.graphql");

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Description = styled.div`
  text-align: center;
  margin: 60px 20px 80px;
  font-size: 24px;
`;

const AddButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AddButton = styled(Link)`
  color: #333;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    margin-bottom: 8px;
  }
`;

export function PumpListScreen() {
  const menuApi = useMenuApi();
  const { data, loading, error, subscribeToMore } = useQuery(PumpsQuery);
  useOnlineStatusSubscription("myPumps", data, subscribeToMore);

  if (loading) {
    return <TextPage>Загрузка...</TextPage>;
  }

  if (error) {
    return <TextPage>Произошла ошибка</TextPage>;
  }

  return (
    <Container>
      <Header actionIcon={MenuIcon} onAction={menuApi.open} />
      {data.myPumps.length > 0 ? (
        <List pumps={data.myPumps} />
      ) : (
        <>
          <Description>Список устройств пуст</Description>
          <AddButtonContainer>
            <AddButton to={`/device/add`}>
              <IconPlus />
              Добавить
            </AddButton>
          </AddButtonContainer>
        </>
      )}
    </Container>
  );
}
