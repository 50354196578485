import { useEffect } from "react";

export const useUpdater = (key, Subscription, subscribeToMore, variables) => {
  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: Subscription,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data || !subscriptionData.data.pump) {
          return prev;
        }

        const { [key]: value } = subscriptionData.data.pump;
        return { ...prev, pump: { ...prev.pump, [key]: value } };
      },
      variables,
    });

    return () => {
      unsubscribe();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
