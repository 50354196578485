import React, { useState, useRef } from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import useOnClickOutside from "use-onclickoutside";

import AppIconAsset from "@smartheat/common/png/streamline-icon-volume-up@24x24.png";
import ServiceIconAsset from "@smartheat/common/png/streamline-icon-cog-double-2@24x24.png";
import GraphIconAsset from "@smartheat/common/png/streamlinehq-analytics-graph-line-business-products-24.png";
import { ReactComponent as MenuIcon } from "@smartheat/common/svg/menu2.svg";

const Container = styled.div`
  background-color: #eee;
  min-height: 100%;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #888;
  background: linear-gradient(180deg, #0d0d0d 0%, #2a2a2a 100%);
  color: white;
  padding: 14px 18px 30px;
  margin-bottom: -16px;
`;

const MenuButtonContainer = styled.div`
  position: relative;
`;

const MenuButton = styled.button`
  padding: 0;
  background: transparent none;
  border: 0 none;

  &:focus {
    outline: 0 none;
  }

  svg {
    display: block;
    width: 32px;
    height: 32px;
  }
`;

const MenuContainer = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  color: #333333;
  padding: 16px;
  background: #fffffe;
  box-shadow: 1px 1px 7px rgba(52, 70, 91, 0.2);
  border-radius: 4px;
`;

const MenuLink = styled(Link)`
  white-space: nowrap;
  text-decoration: none;
  color: #333;
`;

const Content = styled.div`
  background-color: white;
  border-radius: 16px;
  padding: 16px 16px 0;
  box-shadow: 1px 1px 7px rgba(52, 70, 91, 0.2);
`;

const Title = styled.h2`
  margin: 0;
  font-size: 18px;
  font-weight: bold;
`;

const PumpCard = styled.div`
  padding: 16px;
  border-top: 1px solid #e3e5eb;

  &:first-of-type {
    border-top: 0 none;
  }
`;

const PumpId = styled.div`
  color: #7e7a85;
  font-size: 14px;
`;

const PumpLinks = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  user-select: none;
`;

const PumpLink = styled(Link)`
  margin-left: 12px;
  width: 40px;
  height: 40px;
  border: 1px solid #333;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(p) => (p.online ? 1 : 0.33)};
  pointer-events: ${(p) => (p.online ? "all" : "none")};

  &:first-child {
    margin-left: 0;
  }

  img {
    display: block;
    width: 24px;
    height: 24px;
  }
`;

const PumpInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const PumpTitle = styled.div`
  font-size: 16px;
  font-weight: normal;
`;

const PumpCustomer = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const Bottom = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const PumpNetwork = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const NetworkIndicator = styled.div`
  margin-right: 8px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(p) => (p.online ? "#4fdf4d" : "#dd4e4e")};
`;

export function PumpList({ pumps }) {
  const menuContainerRef = useRef();
  const [menuIsOpened, setMenuIsOpened] = useState(false);

  useOnClickOutside(menuContainerRef, () => {
    setMenuIsOpened(false);
  });

  return (
    <Container>
      <Header>
        <Title>Pumps</Title>
        <MenuButtonContainer ref={menuContainerRef}>
          <MenuButton onClick={() => setMenuIsOpened((state) => !state)}>
            <MenuIcon fill="#fff" />
          </MenuButton>
          {menuIsOpened && (
            <MenuContainer>
              <MenuLink to="/device/list">My pumps</MenuLink>
            </MenuContainer>
          )}
        </MenuButtonContainer>
      </Header>
      <Content>
        {pumps.map((pump) => (
          <PumpCard key={pump.id}>
            <PumpInfo>
              <PumpTitle>{pump.title}</PumpTitle>
              <PumpCustomer>{pump.customer.name}</PumpCustomer>
            </PumpInfo>
            <Bottom>
              <div>
                <PumpNetwork>
                  {pump.networkNode && <NetworkIndicator online={pump.networkNode.online} />}
                  {pump.networkNode ? pump.networkNode.name : "n/a"}
                </PumpNetwork>
                <PumpId>{pump.id}</PumpId>
              </div>
              {pump.networkNode && (
                <PumpLinks>
                  <PumpLink to={`/device/${pump.id}`} online={pump.networkNode.online}>
                    <img src={AppIconAsset} alt="App" />
                  </PumpLink>
                  <PumpLink to={`/device/service-menu/${pump.id}`} online={pump.networkNode.online}>
                    <img src={ServiceIconAsset} alt="Сервисное меню" />
                  </PumpLink>
                  <PumpLink
                    online
                    as="a"
                    href={`http://app.brosk.ru:9999/d/1eM5Z5iVk/pumps?orgId=1&var-pumpId=${pump.id}`}
                    target="_blank"
                  >
                    <img src={GraphIconAsset} alt="Графики" />
                  </PumpLink>
                </PumpLinks>
              )}
            </Bottom>
          </PumpCard>
        ))}
      </Content>
    </Container>
  );
}
