import _objectSpread from "/home/denis/workspace/smartheat/pump-services/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
export var angleData = {};
var PI2 = Math.PI * 2;

var getAngleData = function getAngleData(index) {
  return {
    deg: index * 360,
    rad: index * PI2,
    cos: Math.cos(index * PI2),
    sin: Math.sin(index * PI2)
  };
};

for (var i = 0; i < 48; i++) {
  var data = getAngleData(i / 48);
  angleData[data.deg] = _objectSpread(_objectSpread({}, data), {}, {
    half: i % 2 === 1
  });
}