import { useContext } from "react";
import { DeviceApiContext } from "./DeviceApiContext";
export function useDeviceApi() {
  var api = useContext(DeviceApiContext);

  if (!api) {
    throw new Error("Provide api to DeviceApiContext");
  }

  return api;
}