export function decimalize(number) {
  var decimals = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return decimals > 0 ? number / Math.pow(10, decimals) : number;
}
export function formatDecimalNumber(number) {
  var decimals = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

  if (decimals === 0) {
    return "".concat(number);
  }

  return decimalize(number, decimals).toFixed(decimals);
}