import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { loader } from "graphql.macro";

import { useMedia } from "@smartheat/common/utils/useMedia";

import { useOnlineStatusSubscription } from "../../utils/useOnlineStatusSubscription/useOnlineStatusSubscription";
import { FullScreenMessage } from "../../components/FullScreenMessage/FullScreenMessage";

import { PumpList } from "./PumpList";
import { PumpTable } from "./PumpTable";

const PumpsQuery = loader("./PumpsQuery.graphql");

export function PumpAdmin() {
  const { data, loading, error, subscribeToMore } = useQuery(PumpsQuery);
  const isSmall = useMedia(["(max-width: 600px)"]);
  useOnlineStatusSubscription("managedPumps", data, subscribeToMore);

  if (loading) {
    return <FullScreenMessage>Загрузка...</FullScreenMessage>;
  }

  if (error) {
    return <FullScreenMessage>Ошибка</FullScreenMessage>;
  }

  return isSmall ? <PumpList pumps={data.managedPumps} /> : <PumpTable pumps={data.managedPumps} />;
}
