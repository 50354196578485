import React from "react";
import { createGlobalStyle } from "styled-components/macro";
import { ApolloProvider } from "@apollo/react-hooks";
import { BrowserRouter as Router } from "react-router-dom";

import { apolloClient, statusManager } from "./apolloClient";

import { AuthProvider } from "./components/Auth/AuthProvider";
import { OnlineStatus } from "./components/OnlineStatus/OnlineStatus";
import { App } from "./components/App";

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
    padding: 0;
    margin: 0;
  }

  body, input, button {
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    color: #333;
    font-weight: 300;
  }

  #root {
    min-height: 100%;
    position: relative;
    height: 0;
  }

  .ReactModal__Overlay {
    opacity: 0;
    backdrop-filter: blur(0);
    transition: all 300ms ease-in-out;
  }

  .ReactModal__Overlay--after-open {
      opacity: 1;
      backdrop-filter: blur(6px);
  }

  .ReactModal__Overlay--before-close {
      opacity: 0;
      backdrop-filter: blur(0);
      pointer-events: none;
  }
`;

function Root() {
  return (
    <ApolloProvider client={apolloClient}>
      <Router>
        <OnlineStatus manager={statusManager}>
          <AuthProvider>
            <GlobalStyle />
            <App />
          </AuthProvider>
        </OnlineStatus>
      </Router>
    </ApolloProvider>
  );
}

export default Root;
