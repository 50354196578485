import _slicedToArray from "/home/denis/workspace/smartheat/pump-services/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useSetRegisterMutation } from "../DeviceData/useSetRegisterMutation";
export function useRegister(regName, registersData, registersConfig) {
  var rawValue = regName ? registersData[regName] : undefined;
  var regConfig = regName ? registersConfig[regName] : undefined;
  return useMemo(function () {
    return rawValue === undefined || regConfig === undefined ? undefined : decodeRegValue(rawValue, regConfig);
  }, [rawValue, regConfig]);
}
export function useRegSlot(regName, registersData, registersConfig) {
  var _useSetRegisterMutati = useSetRegisterMutation(),
      _useSetRegisterMutati2 = _slicedToArray(_useSetRegisterMutati, 1),
      setRegister = _useSetRegisterMutati2[0];

  var rawValue = registersData[regName];
  var regConfig = registersConfig && registersConfig[regName];
  var regValue = useMemo(function () {
    return rawValue === undefined || regConfig === undefined ? undefined : decodeRegValue(rawValue, regConfig);
  }, [rawValue, regConfig]);

  var _useState = useState(null),
      _useState2 = _slicedToArray(_useState, 2),
      syncValue = _useState2[0],
      setSyncValue = _useState2[1];

  var setValue = useCallback(function (newValue) {
    if (!regConfig) return;
    var newRawValue = encodeRegValue(newValue, regConfig);
    setRegister(regName, newRawValue);
    setSyncValue(newValue);
  }, [regName, setRegister, regConfig]);
  useEffect(function () {
    if (!syncValue || syncValue !== regValue) {
      return;
    }

    var timeout = setTimeout(function () {
      setSyncValue(null);
    }, 1000);
    return function () {
      clearTimeout(timeout);
    };
  }, [syncValue, regValue]);
  useEffect(function () {
    if (syncValue === null) {
      return;
    }

    var timeout = setTimeout(function () {
      setSyncValue(null);
    }, 5000);
    return function () {
      clearTimeout(timeout);
    };
  }, [syncValue]);
  var value = syncValue === null ? regValue : syncValue;
  var syncing = syncValue != null;
  var isDefined = value != undefined;
  return useMemo(function () {
    return {
      value: value,
      syncing: syncing,
      setValue: setValue,
      isDefined: isDefined
    };
  }, [value, syncing, setValue, isDefined]);
}

function decodeRegValue(regValue, config) {
  if (config.type === "NUM") {
    return config.decimals ? regValue / Math.pow(10, config.decimals) : regValue;
  }

  if (config.type === "BOOL") {
    return regValue === 1;
  }

  return regValue;
}

function encodeRegValue(value, config) {
  if (config.type === "NUM") {
    return Math.round(config.decimals ? value * Math.pow(10, config.decimals) : value);
  }

  if (config.type === "BOOL") {
    return value ? 1 : 0;
  }

  return Math.round(value);
}