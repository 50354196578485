import { useContext } from "react";
import { MenuContext } from "./MenuContext";
export function useMenuApi() {
  var api = useContext(MenuContext);

  if (!api) {
    throw new Error("Use MenuContainer");
  }

  return api;
}