import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { loader } from "graphql.macro";

import { FullScreenMessage } from "../FullScreenMessage/FullScreenMessage";

import { AuthContext } from "./AuthContext";
import { Guest } from "./Guest";

const AuthQuery = loader("./AuthQuery.graphql");

export function AuthProvider({ children }) {
  const { data, loading, error } = useQuery(AuthQuery);

  if (loading) {
    return <FullScreenMessage>Авторизация...</FullScreenMessage>;
  }

  if (error) {
    return <FullScreenMessage>Ошибка авторизации. Попробуйте позже.</FullScreenMessage>;
  }

  return <AuthContext.Provider value={data.viewer || Guest}>{children}</AuthContext.Provider>;
}
