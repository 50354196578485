import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { loader } from "graphql.macro";

import { DeviceRouter } from "@smartheat/common/deviceui/DeviceRouter";
import { DeviceDataContext } from "@smartheat/common/deviceui/DeviceData/DeviceDataContext";
import { DeviceApiContext } from "@smartheat/common/deviceui/DeviceData/DeviceApiContext";
import { ConfigContext } from "@smartheat/common/deviceui/Config/ConfigContext";
import { MenuContainer } from "@smartheat/common/deviceui/MenuContainer/MenuContainer";
import { TextPage } from "@smartheat/common/deviceui/TextPage/TextPage";

import { ReactComponent as InfoIcon } from "@smartheat/common/svg/menu/information-circle.svg";
import { ReactComponent as ScheduleIcon } from "@smartheat/common/svg/menu/circle-clock.svg";
import { ReactComponent as ControlIcon } from "@smartheat/common/svg/menu/vertical-slider-square.svg";
import { ReactComponent as SpeechIcon } from "@smartheat/common/svg/menu/help-chat-2.svg";
import { ReactComponent as WrenchIcon } from "@smartheat/common/svg/menu/wrench.svg";
import { ReactComponent as ShowerIcon } from "@smartheat/common/svg/menu/hotel-shower-head.svg";

import { useUpdater } from "../../utils/useUpdater";

import { DeviceQueryProvider } from "./DeviceQueryProvider";
import { useDeviceApiCreator } from "./useDeviceApiCreator";
import { config } from "./config";

const HeatDataQuery = loader("./api/HeatDataQuery.graphql");
const HardwareInfoQuery = loader("./api/HardwareInfoQuery.graphql");
const HeatConfigSubscription = loader("./api/HeatConfigSubscription.graphql");
const HeatModeSubscription = loader("./api/HeatModeSubscription.graphql");
const ThermostatValueSubscription = loader("./api/ThermostatValueSubscription.graphql");
const PumpTimeSubscription = loader("./api/PumpTimeSubscription.graphql");
const ServiceMenuQuery = loader("./api/ServiceMenuQuery.graphql");
const UserAppConfigQuery = loader("./api/UserAppConfigQuery.graphql");

export function AppAdapter() {
  const { id } = useParams();
  const heatDataQuery = useQuery(HeatDataQuery, { variables: { id } });

  const hardwareInfoQuery = useQuery(HardwareInfoQuery, { variables: { id } });
  const hardwareInfo =
    hardwareInfoQuery.data && hardwareInfoQuery.data.pump && hardwareInfoQuery.data.pump.hardwareInfo
      ? hardwareInfoQuery.data.pump.hardwareInfo
      : undefined;
  const controllerApi = hardwareInfo && hardwareInfo.controllerApi;

  const serviceMenuQuery = useQuery(ServiceMenuQuery, { variables: { id } });
  const serviceMenuData =
    serviceMenuQuery.data && serviceMenuQuery.data.pump && serviceMenuQuery.data.pump.serviceMenu;
  const hasServiceMenu = serviceMenuData && controllerApi !== "hw4"
    ? serviceMenuData.availableForUser
    : false;

  const hasUserAppConfig = controllerApi === "hw3" || controllerApi === "hw4";
  const userAppConfigQuery = useQuery(UserAppConfigQuery, {
    variables: { id },
    skip: !hasUserAppConfig
  });
  /*const userAppConfigData = userAppConfigQuery.data;
  const userAppConfig =
    userAppConfigData && userAppConfigData.pump && userAppConfigData.pump.userAppConfig;*/

  useUpdater("heatMode", HeatModeSubscription, heatDataQuery.subscribeToMore, { id });
  useUpdater("thermostatValue", ThermostatValueSubscription, heatDataQuery.subscribeToMore, { id });
  useUpdater("heatConfig", HeatConfigSubscription, heatDataQuery.subscribeToMore, { id });
  useUpdater("pumpTime", PumpTimeSubscription, heatDataQuery.subscribeToMore, { id });

  const deviceApi = useDeviceApiCreator(id);

  const prefixPath = `/device/${id}`;

  const menu = useMemo(
    () => [
      controllerApi !== "hw4" ? {
        path: `${prefixPath}/info`,
        title: "Информация",
        Icon: InfoIcon,
      } : null,
      controllerApi === "hw1" ? {
        path: `${prefixPath}/schedule`,
        title: "График",
        Icon: ScheduleIcon,
      } : null,
      controllerApi !== "hw4" ? {
        path: `${prefixPath}/modes`,
        title: "Режим работы",
        Icon: ControlIcon,
      } : null,
      controllerApi === "hw1" ? {
        path: `${prefixPath}/hws`,
        title: "ГВС",
        Icon: ShowerIcon,
      } : null,
      hasServiceMenu ? {
        path: `${prefixPath}/user-service-menu`,
        title: "Сервисное меню",
        Icon: WrenchIcon,
      } : null,
      {
        path: `${prefixPath}/help`,
        title: "Помощь",
        Icon: SpeechIcon,
      },
    ].filter(item => !!item),
    [prefixPath, hasServiceMenu, controllerApi],
  );

  const extendedConfig = useMemo(
    () => ({
      ...config,
      backLocation: id.startsWith("p:") ? "/device/list" : "/admin/pumps",
      prefixPath,
    }),
    [prefixPath, id],
  );

  if (heatDataQuery.loading || hardwareInfoQuery.loading || userAppConfigQuery.loading) {
    return <TextPage backTo={extendedConfig.backLocation}>Загрузка...</TextPage>;
  }

  if (heatDataQuery.error || hardwareInfoQuery.error || userAppConfigQuery.error) {
    return <TextPage backTo={extendedConfig.backLocation}>Ошибка шлюза</TextPage>;
  }

  return (
    <DeviceApiContext.Provider value={deviceApi}>
      <DeviceDataContext.Provider value={heatDataQuery.data.pump}>
        <ConfigContext.Provider value={extendedConfig}>
          <DeviceQueryProvider id={id}>
            <MenuContainer menu={menu}>
              <DeviceRouter hardwareInfo={hardwareInfo} />
            </MenuContainer>
          </DeviceQueryProvider>
        </ConfigContext.Provider>
      </DeviceDataContext.Provider>
    </DeviceApiContext.Provider>
  );
}
