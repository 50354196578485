import Konva from "konva";

function getMatrix(node) {
  const origTrans = node._clearTransform(); // don't clear translation


  node.attrs.x = origTrans.x;
  node.attrs.y = origTrans.y;
  const it = node.getAbsoluteTransform();
  const matrix = it.invert().getMatrix().slice();

  node._setTransform(origTrans);

  return matrix;
}

Konva.DD.CANCEL = "CANCEL";

Konva.Node.prototype.toLocalPosition = function toLocalPosition(absPos) {
  const invMatrix = getMatrix(this);
  const translation = translate(invMatrix, absPos.x, absPos.y);
  return {
    x: this.attrs.x + translation.x,
    y: this.attrs.y + translation.y
  };
};

Konva.Node.prototype._createDragElement = function _createDragElement(evt) {
  const pointerId = evt ? evt.pointerId : undefined;
  const stage = this.getStage();
  const ap = this.getAbsolutePosition();
  const pos = stage._getPointerById(pointerId) || stage._changedPointerPositions[0] || ap;

  Konva.DD._dragElements.set(this._id, {
    node: this,
    startPointerPos: pos,
    offset: {
      x: pos.x - ap.x,
      y: pos.y - ap.y
    },
    dragStatus: "ready",
    pointerId,
    invNodeMatrix: getMatrix(this),
    nodeLocalPos: this.getPosition()
  });
};

function translate(m, x, y) {
  return {
    x: m[4] + m[0] * x + m[2] * y,
    y: m[5] + m[1] * x + m[3] * y
  };
}

Konva.Node.prototype._setDragPosition = function _setDragPosition(evt, elem) {
  const pos = this.getStage()._getPointerById(elem.pointerId);

  const dbf = this.getDragBoundFunc();

  if (!pos || !dbf) {
    return;
  }

  const absPos = {
    x: pos.x - elem.offset.x,
    y: pos.y - elem.offset.y
  };
  const translation = translate(elem.invNodeMatrix, absPos.x, absPos.y);
  const localPos = {
    x: elem.nodeLocalPos.x + translation.x,
    y: elem.nodeLocalPos.y + translation.y
  };
  const res = dbf.call(this, {
    localPos,
    absPos,
    translation,
    target: this,
    evt
  });

  if (res === Konva.DD.CANCEL) {
    this.stopDrag();
  }
};

const originalDestroy = Konva.Node.prototype.destroy;

Konva.Node.prototype.destroy = function destroy() {
  if (Konva.DD._elements) {
    Konva.DD._elements.delete(this._id);
  }

  originalDestroy.call(this);
};

Konva.Node.prototype._listenDrag = function _listenDrag() {
  this._dragCleanup();

  if (!Konva.DD._elements) {
    Konva.DD._elements = new Map();
  }

  Konva.DD._elements.set(this._id, this);

  this.on("mousedown.konva touchstart.konva dragaffect.konva", function (evt) {
    var shouldCheckButton = evt.evt["button"] !== undefined;
    var canDrag = !shouldCheckButton || Konva.dragButtons.indexOf(evt.evt["button"]) >= 0;

    if (!canDrag) {
      return;
    }

    if (this.isDragging()) {
      return;
    }

    var hasDraggingChild = false;

    Konva.DD._dragElements.forEach(elem => {
      if (this.isAncestorOf(elem.node)) {
        hasDraggingChild = true;
      }
    }); // nested drag can be started
    // in that case we don't need to start new drag


    if (!hasDraggingChild) {
      this._createDragElement(evt);
    }
  });
};

Konva.Node.prototype._dragCleanup = function _dragCleanup() {
  this.off("mousedown.konva");
  this.off("touchstart.konva");
  this.off("dragaffect.konva");

  if (Konva.DD._elements) {
    Konva.DD._elements.delete(this._id);
  }
};

const TOUCHSTART = "touchstart";
const MOUSEDOWN = "mousedown";
const CONTENT_TOUCHSTART = "contentTouchstart";
const CONTENT_MOUSEDOWN = "contentMousedown";

function getNodeFromDragArea(shape, pointerPos) {
  // do not check if shape is draggable
  if (shape && shape.attrs.draggable) {
    return null;
  } // return if there is no draggable elements


  if (!Konva.DD._elements) {
    return null;
  }

  let memoDistance = 0;
  let affectedNode = null;

  Konva.DD._elements.forEach(node => {
    if (!node.attrs.dragAreaRadius) {
      return;
    } // node is dettached


    if (!node.parent) {
      return;
    }

    const absPos = node.getAbsolutePosition();
    const dx = absPos.x - pointerPos.x;
    const dy = absPos.y - pointerPos.y;
    const distance = Math.sqrt(dx * dx + dy * dy); // too far

    if (distance > node.attrs.dragAreaRadius) {
      return;
    } // prev node was closer


    if (affectedNode && memoDistance < distance) {
      return;
    }

    affectedNode = node;
    memoDistance = distance;
  });

  return affectedNode;
}

Konva.Stage.prototype._mousedown = function _mousedown(evt) {
  // workaround for mobile IE to force touch event when unhandled pointer event elevates into a mouse event
  if (Konva.UA.ieMobile) {
    return this._touchstart(evt);
  }

  this.setPointersPositions(evt);

  var pointerId = Konva.Util._getFirstPointerId(evt);

  const pointerPos = this.getPointerPosition();
  var shape = this.getIntersection(pointerPos);
  Konva.DD.justDragged = false;
  Konva.listenClickTap = true;
  const affectedNode = getNodeFromDragArea(shape, pointerPos);

  if (affectedNode) {
    affectedNode._fireAndBubble("dragaffect", {
      evt: evt,
      pointerId
    });
  }

  if (shape && shape.isListening()) {
    this.clickStartShape = shape;

    shape._fireAndBubble(MOUSEDOWN, {
      evt: evt,
      pointerId
    });
  } else {
    this._fire(MOUSEDOWN, {
      evt: evt,
      target: this,
      currentTarget: this,
      pointerId
    });
  } // content event


  this._fire(CONTENT_MOUSEDOWN, {
    evt: evt
  }); // Do not prevent default behavior, because it will prevent listening events outside of window iframe
  // we used preventDefault for disabling native drag&drop
  // but userSelect = none style will do the trick
  // if (evt.cancelable) {
  //   evt.preventDefault();
  // }

};

Konva.Stage.prototype._touchstart = function _touchstart(evt) {
  this.setPointersPositions(evt);
  var triggeredOnShape = false;

  this._changedPointerPositions.forEach(pos => {
    var shape = this.getIntersection(pos);
    Konva.listenClickTap = true;
    Konva.DD.justDragged = false;
    const hasShape = shape && shape.isListening();
    const affectedNode = getNodeFromDragArea(shape, pos);

    if (affectedNode) {
      affectedNode._fireAndBubble("dragaffect", {
        evt: evt,
        pointerId: pos.id
      }, this);
    }

    if (!hasShape) {
      return;
    }

    if (Konva.captureTouchEventsEnabled) {
      shape.setPointerCapture(pos.id);
    }

    this.tapStartShape = shape;

    shape._fireAndBubble(TOUCHSTART, {
      evt: evt,
      pointerId: pos.id
    }, this);

    triggeredOnShape = true; // only call preventDefault if the shape is listening for events

    if (shape.isListening() && shape.preventDefault() && evt.cancelable) {
      evt.preventDefault();
    }
  });

  if (!triggeredOnShape) {
    this._fire(TOUCHSTART, {
      evt: evt,
      target: this,
      currentTarget: this,
      pointerId: this._changedPointerPositions[0].id
    });
  } // content event


  this._fire(CONTENT_TOUCHSTART, {
    evt: evt
  });
};