export function resizingWorker(event, _ref, _ref2) {
  var setDisplayTimeByAngle = _ref.setDisplayTimeByAngle,
      setWorkerState = _ref.setWorkerState;
  var clockPos = _ref2.clockPos,
      edge = _ref2.edge,
      rangeIndex = _ref2.rangeIndex;
  setWorkerState({
    edge: edge,
    rangeIndex: rangeIndex
  });
  setDisplayTimeByAngle(clockPos.angle);
  return {
    type: "RESIZING",
    handleDrag: function handleDrag(event, _ref3, _ref4) {
      var workerState = _ref3.workerState,
          handleEdgeMove = _ref3.handleEdgeMove;
      var clockPos = _ref4.clockPos,
          distance = _ref4.distance;
      var edge = workerState.edge,
          rangeIndex = workerState.rangeIndex;
      handleEdgeMove(event, {
        index: rangeIndex,
        edge: edge,
        clockPos: clockPos,
        distance: distance
      });
    },
    handleDragEnd: function handleDragEnd(event, _ref5) {
      var workerState = _ref5.workerState,
          handleEdgeMoveEnd = _ref5.handleEdgeMoveEnd;
      var edge = workerState.edge,
          rangeIndex = workerState.rangeIndex;
      handleEdgeMoveEnd(rangeIndex, edge);
    }
  };
}