import { useContext } from "react";
import { ConfigContext } from "./ConfigContext";
export function useConfig() {
  var config = useContext(ConfigContext);

  if (!config) {
    throw new Error("Provide config to ConfigContext");
  }

  return config;
}